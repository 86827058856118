export const DefinitionTableContentAdminRelease1 = [
  {
    id: "#admin-definitions",
    name: "Administration",
    list: [
      {
        id: "#settings-reset",
        name: "Reset Default Settings"
      },
      {
        id: "#factory-reset",
        name: "Factory Reset"
      },
      {
        id: "#check-update",
        name: "Check Firmware"
      },
      {
        id: "#soft-reboot",
        name: "Reboot"
      },
      {
        id: "#format-sd-card",
        name: "Format SD Card (CR40 Only)"
      },
      {
        id: "#camera-info",
        name: "Get Camera Information"
      },
      {
        id: "#send-direct-command",
        name: "Send Direct Command to Device"
      }
    ]
  }
]