export const DefinitionTableContentRoadmap = [
  {
    id: "#camera",
    name: "Camera",
    list: [
      {
        id: "#cameraState",
        name: "Camera State",
      },
      {
        id: "#cameraMode",
        name: "Camera Mode",
      },
      {
        id: "#version",
        name: "Version",
      },
      {
        id: "#simStatus",
        name: "SIM Status",
        list: [],
      },
      {
        id: "#connectionStatus",
        name: "Connection Status",
        list: [],
      },
      {
        id: "#cableSerialNumber",
        name: "Cable Serial Number",
        list: [],
      },
    ],
  },
  {
    id: "#events",
    name: "Events",
    list: [
      {
        id: "#eventMetadata",
        name: "Event Metadata",
      },
      {
        id: "#eventMedia",
        name: "Event Media",
      },
      {
        id: "#adasEvents",
        name: "ADAS Events",
      },
      {
        id: "#dmsEvents",
        name: "DMS Events",
      },
    ],
  },
  {
    id: "#clips",
    name: "Clips",
    list: [
      {
        id: "#allCameras",
        name: "All Cameras",
      },
      {
        id: "#adasClips",
        name: "ADAS Clips",
      },
      {
        id: "#aggressiveDriving",
        name: "Aggressive Driving Clips",
      },
      {
        id: "#clipMedia",
        name: "Clip Media",
      },
    ],
  },
  {
    id: "#settings",
    name: "Camera Settings",
    list: [
      {
        id: "#audio",
        name: "Audio",
      },
      {
        id: "#insideCamera",
        name: "Inside Camera",
      },
      {
        id: "#display",
        name: "Display",
      },
      {
        id: "#displayRequiresIgnition",
        name: "Display Requires Ignition",
      },
      {
        id: "#okPresto",
        name: "Ok Presto",
      },
      {
        id: "#videoBufferSec",
        name: "Video Buffer Seconds",
      },
      {
        id: "#outsideRes",
        name: "Outside Camera Resolution",
      },
      {
        id: "#standbyTime",
        name: "Standby Time",
      },
      {
        id: "#beeps",
        name: "Beeps",
      },
      {
        id: "#CAN",
        name: "CAN",
      },
      {
        id: "#motionAlerts",
        name: "Motion Alerts",
      },
      {
        id: "#vehicle",
        name: "Vehicle",
      },
      {
        id: "#tapToCreateClip",
        name: "Tap to Create Clip",
      },
      {
        id: "#orientation",
        name: "Orientation",
      },
      {
        id: "#bumpSensitivity",
        name: "Bump Sensitivity",
      },
      {
        id: "#inCabAlerts",
        name: "In Cab Alerts",
      },
      {
        id: "#eventQueueSize",
        name: "Event Queue Size",
      },
      {
        id: "#guardMode",
        name: "Guard Mode",
      },
      {
        id: "#ignition",
        name: "Ignition (deprecated)",
      },
      {
        id: "#voltage",
        name: "Voltage",
      },
      {
        id: "#startupScreen",
        name: "Startup Screen",
      },
      {
        id: "#vbusSettings",
        name: "VBUS",
      },
    ],
  },
  {
    id: "#sceneSettings",
    name: "Scene Settings",
    list: [
      {
        id: "#mountHeight",
        name: "Mount Height",
      },
    ],
  },
  {
    id: "#adasSettings",
    name: "ADAS Settings",
    list: [
      {
        id: "#tailgating",
        name: "Tailgating",
      },
      {
        id: "#frontalCollisionWarning",
        name: "Frontal Collision Warning",
      },
      {
        id: "#stopAndGo",
        name: "Stop and Go",
      },
      {
        id: "#laneDeparture",
        name: "Lane Departure",
      },
    ],
  },
  {
    id: "#dmsSettings",
    name: "DMS Settings",
    list: [
      {
        id: "#distracted",
        name: "Distracted",
      },
      {
        id: "#cellPhone",
        name: "Cell Phone",
      },
      {
        id: "#fatigue",
        name: "Fatigue",
      },
    ],
  },
  {
    id: "#aggressiveDrivingSettings",
    name: "Aggressive Driving Settings",
    list: [
      {
        id: "#harshAcceleration",
        name: "Harsh Acceleration",
      },
      {
        id: "#harshBraking",
        name: "Harsh Braking",
      },
      {
        id: "#harshCorneringRight",
        name: "Harsh Cornering Right",
      },
      {
        id: "#harshCorneringLeft",
        name: "Harsh Cornering Left",
      },
    ],
  },
  {
    id: "#eventSettings",
    name: "Event Settings",
    list: [
      {
        id: "#telemetryEvent",
        name: "Telemetry",
      },
      {
        id: "#tripsEvent",
        name: "Trips",
      },
      {
        id: "#stateEvent",
        name: "State",
      },
      {
        id: "#modeEvent",
        name: "Mode",
      },
      {
        id: "#diagnosticsEvent",
        name: "Diagnostics",
      },
      {
        id: "#eventsEvent",
        name: "Events",
      },
      {
        id: "#systemEvent",
        name: "System",
      },
    ],
  },
  {
    id: "#gnssSettings",
    name: "GNSS Settings",
    list: [
      {
        id: "#pinning",
        name: "Pinning",
      },
    ],
  },
  {
    id: "#overrideSettings",
    name: "Override Settings",
    list: [
      {
        id: "#forceCan",
        name: "Force CAN",
      },
    ],
  },
  {
    id: "#hyperlapseSettings",
    name: "Hyperlapse Settings",
    list: [
      {
        id: "#insideCameraHyperlapse",
        name: "Inside Camera",
      },
      {
        id: "#outsideCameraHyperlapse",
        name: "Outside Camera",
      },
    ],
  },
  {
    id: "#watermarkSettings",
    name: "Watermark Settings",
    list: [
      {
        id: "#isEnabledWatermark",
        name: "Is Enabled",
      },
      {
        id: "#options",
        name: "Options",
      },
    ],
  },
  {
    id: "#telemetry",
    name: "Telemetry",
    list: [],
  },
  {
    id: "#can",
    name: "CAN Data",
    list: [],
  },
  {
    id: "#vbus",
    name: "VBUS Data",
    list: [],
  },
  {
    id: "#diagnostics",
    name: "Diagnostics",
    list: [],
  },
  {
    id: "#journalEntry",
    name: "Journal Entry",
    list: [],
  },
  {
    id: "#trips",
    name: "Trips",
    list: [],
  },
  {
    id: "#state",
    name: "State",
    list: [],
  },
  {
    id: "#system",
    name: "System",
    list: [],
  },
  {
    id: "#settingsUpdate",
    name: "Settings Update",
    list: [],
  },
  {
    id: "#screenBranding",
    name: "Screen Branding",
    list: [],
  },
  {
    id: "#firmwareManagement",
    name: "Firmware Management",
    list: [
      {
        id: "#firmwareManagementInner",
        name: "Firmware Groups",
      },
      {
        id: "#firmware-group-cameras",
        name: "Firmware Group Cameras",
      },
      {
        id: "#associate-camera-with-firmware-group",
        name: "Associate Camera with Firmware Group",
      },
      {
        id: "#associate-multiple-cameras-with-firmware-group",
        name: "Associate Multiple Cameras with Firmware Group",
      },
    ],
  },
  {
    id: "#admin",
    name: "Administration",
    list: [
      {
        id: "#settings-reset",
        name: "Reset Default Settings",
      },
      {
        id: "#factory-reset",
        name: "Factory Reset",
      },
      {
        id: "#check-update",
        name: "Check Firmware",
      },
      {
        id: "#soft-reboot",
        name: "Reboot",
      },
      {
        id: "#format-sd-card",
        name: "Format the SD Card (CR40 Only)"
      },
      {
        id: "#camera-info",
        name: "Get Camera Information",
      },
      {
        id: "#send-direct-command",
        name: "Send Direct Command to Device"
      }
    ],
  },
];
