export const DefinitionTableContentR2 = [
  {
    id: "#camera",
    name: "Camera",
    list: [
      {
        id: "#cameraState",
        name: "Camera State",
      },
      {
        id: "#cameraMode",
        name: "Camera Mode",
      },
      {
        id: "#version",
        name: "Version",
      },
      {
        id: "#simStatus",
        name: "SIM Status",
        list: [],
      },
      {
        id: "#connectionStatus",
        name: "Connection Status",
        list: [],
      },
      {
        id: "#cableSerialNumber",
        name: "Cable Serial Number",
        list: [],
      },
    ],
  },
  {
    id: "#events",
    name: "Events",
    list: [
      {
        id: "#eventMetadata",
        name: "Event Metadata",
      },
      {
        id: "#eventMedia",
        name: "Event Media",
      },
      {
        id: "#adasEvents",
        name: "ADAS Events",
      },
      {
        id: "#dmsEvents",
        name: "DMS Events",
      },
    ],
  },
  {
    id: "#clips",
    name: "Clips",
    list: [
      {
        id: "#allCameras",
        name: "All Cameras",
      },
      {
        id: "#adasClips",
        name: "ADAS Clips",
      },
      {
        id: "#aggressiveDriving",
        name: "Aggressive Driving Clips",
      },
      {
        id: "#clipMedia",
        name: "Clip Media",
      },
    ],
  },
  {
    id: "#telemetry",
    name: "Telemetry",
    list: [],
  },
  {
    id: "#can",
    name: "CAN Data",
    list: [],
  },
  {
    id: "#vbus",
    name: "VBUS Data",
    list: [],
  },
  {
    id: "#diagnostics",
    name: "Diagnostics",
    list: [],
  },
  {
    id: "#journalEntry",
    name: "Journal Entry",
    list: [],
  },
  {
    id: "#trips",
    name: "Trips",
    list: [],
  },
  {
    id: "#state",
    name: "State",
    list: [],
  },
  {
    id: "#system",
    name: "System",
    list: [],
  },
  {
    id: "#settingsUpdate",
    name: "Settings Update",
    list: [],
  },
  {
    id: "#screenBranding",
    name: "Screen Branding",
    list: [],
  }
];
